import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import Heading from "@components/heading/heading"

class GlueForYouPage extends React.Component {
  render() {
    const siteTitle = "Pricing"
    const siteDescription = "Pricing description"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SeoComponent
          title="Is WebriQ GLUE for YOU"
          description={siteDescription}
        />
        <Heading title="Is WebriQ GLUE for YOU" />

        <div className="pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <Row>
                  <Col md={6}>
                    <ul className="checked-list">
                      <li className="mb-4">
                        We have built hundreds of sites on Content Management
                        Systems like WordPress, generically called monolithic
                        Content Management Systems (CMS), and we had done so for
                        many years, up until we stopped.
                      </li>
                      <li className="mb-4">
                        Websites, and web apps should only contain features that
                        are beneficial to the business and to your customers.
                        Any other unneeded functionality tacked on will just be
                        a waste of time and will detract from an effective and
                        customer-centric designed websites.
                      </li>
                      <li className="mb-4">
                        <span className="font-weight-bold">WebriQ GLUE</span> is
                        no longer a monolithic, one-fits-for-all approach. We
                        are using best in class-in-class technologies and gluing
                        them together as Pre-rendered web pages or a single page
                        web application deployed atomically to a global,
                        multi-cloud Content Delivery Network (CDN) on steroids.
                      </li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="checked-list">
                      <li className="mb-4">
                        Digital Experiences is all about consistency and
                        personalization. The technologies we implement are
                        assuring you that your customers will have a consistent
                        brand experience across all your digital assets.
                        Personalization will be achievable depending on the
                        nature of the digital channel accessed.
                      </li>
                      <li className="mb-4">
                        <span className="font-weight-bold">WebriQ GLUE</span> is
                        a basic single file or single page application approach,
                        that will be impressively fast to load, will scale
                        easily, will be completely secured and unhackable, and
                        will work on slow mobile networks and offline if needed.
                      </li>
                      <li className="mb-4">
                        Feel the pain of scaling and securing your Web
                        Publishing tools, and feel the need to publish your
                        content beyond a website, then WebriQ GLUE is for you.
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="mt-5 text-center">
              <Link to="/" className="btn btn-primary btn-orange">
                Return Home
              </Link>
            </div>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default GlueForYouPage
